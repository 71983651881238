<template>
  <div class="table" :style="has_page?'min-height:480px;':''">
    <div class="title">
      <div class="itemcont">
        <div class="item" v-for="(item, index) in title" :class="data_url == item?'on' : ''" :key="index" @click="changeTab(item)">
          {{ index }}<span></span>
        </div>
      </div>
      <button type="button" v-if="search&&Object.keys(search).length>0" @click="showSearch">搜索</button>
    </div>
    <div class="cont">
      <div class="thead">
        <div class="item" v-for="(item, index) in fields" :key="index" 
        :style="'width: '+ (item.width?item.width:'80') +'px;'"  v-html="item.text"></div>
      </div>
      <div class="tbody">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          offset="10"
        >
          <div class="tr" v-for="(item, index) in table_data" :key="index">
            <div class="item" v-for="(tfield, k) in fields" :text="tfield.text" :key="k" :data-id="item.id?item.id:0"
             :style="'width: '+ (tfield.width?tfield.width:'80') +'px;'" v-html="item[k]"></div>
          </div>
        </van-list>
      </div>
    </div>
    <searchForm :searchfun="searchfun" :search="search" :searchData="search_data" v-if="showsearch" />
  </div>
</template>

<script setup>
import { onMounted, toRefs, defineProps, ref } from "vue";
import searchForm from "@/components/selectbox";
import VIEW from "@/axios/view";
import API from "@/axios";

const props = defineProps({
  field: {
    type: Object,
    required: true,
  },
  // title: {
  //   type: Array,
  //   required: true,
  // },
  // tabledata: {
  //   type: String,
  //   required: true,
  // },
  // scrollSwitch: {
  //   type: Boolean,
  //   required: true,
  // },
});

const loading = ref(true);
const finished = ref(false);
const fields = ref([]);
const search = ref({});
const table_data = ref([]);
const title = ref({});
const data_url = ref('');
let api_url = '';
let current_page = 1;
const has_page = ref(false);
const showsearch = ref(false);
let search_data = ref({});


function onLoad() {
  // 异步更新数据
  // setTimeout 仅做示例，真实场景中一般为 ajax 请求
  if(has_page.value){
    getData();
  }else{
     finished.value = true;
     loading.value = false;
  }
  // if (scrollSwitch.value) {
  //   setTimeout(() => {
  //     // let newArr = [];
  //     // that.list = that.list.concat(res.data);

  //     // that.list.forEach((el) => {
  //     //   const result = newArr.findIndex((ol) => {
  //     //     return el.id === ol.id;
  //     //   });
  //     //   if (result !== -1) {
  //     //     newArr[result] = el;
  //     //   } else {
  //     //     newArr.push(el);
  //     //   }
  //     // });
  //     // that.list = newArr;

  //     // 加载状态结束
  //     loading.value = false;

  //     // 数据全部加载完成
  //     if (tabledata.value.length >= 40) {
  //       finished.value = true;
  //     }
  //   }, 1000);
  // } else {
  //   finished.value = false;
  //   loading.value = false;
  // }
}

onMounted(() => {
  if(field.value){
    title.value = field.value.tab_title?field.value.tab_title:{};
    api_url = field.value.url;
    getFields();
  }
});

function getApiUrl(){
  if(data_url.value.indexOf('?') === 0){
    if(api_url.indexOf('?') > -1){
      return api_url.substr(0, api_url.indexOf('?')) + data_url.value;
    }else{
      return api_url + data_url.value;
    }
  }
  return data_url.value;
}

function changeTab(item){
  data_url.value = item;
  current_page = 1;
  loading.value = false;
  finished.value = false;
  getData();
}


function getFields() {
  VIEW({
    url: field.value.url,
    method: "post",
  }).then((res) => {
    let data = res.data.data;
    let tfields = data[1]['form']['field'];
    let onlys = field.value.only;
    if(onlys){
      onlys = Object.values(onlys);
      for(let i in tfields){
        if(onlys.indexOf(i) == -1){
          delete tfields[i];
        }
      }
    }
    fields.value = tfields;
    let tsearch = data[1]['form']['search'];
    for(let i in tsearch){
      if(tsearch[i].default){
        search_data.value[i] = tsearch[i].default;
      }else{
        search_data.value[i] = '';
      }
    }
    search.value = tsearch;
    //fields.value = data[1]['form']['field'];
    data_url.value = data[1]['form']['data_url'];
    has_page.value = data[1]['form']['page'] === false ?false:true;
    getData();
  });
}

function getData() {
  let url = getApiUrl();
  let limit = 10;
  let data = {};
  data.page = current_page;
  data.limit = limit;
  data.search = search_data.value;
  loading.value = true;
  API({
    url: url,
    method: "post",
    data:data,
  }).then((res) => {
    if(current_page == 1){
        table_data.value = res.data.data;
    } else {
        table_data.value = table_data.value.concat(res.data.data);
    }
    if(res.data.data.length < limit){
        finished.value = true;
    } else {
        finished.value = false;
    }
    loading.value = false;
    current_page++;
  });
}
function showSearch() {
  showsearch.value = true;
}
function searchfun(data, is_ok) {
  showsearch.value = false;
  if(is_ok){
    current_page = 1;
    search_data.value = data;
    getData();
  }
}
const { field } = toRefs(props);
</script>

<style lang="less" scoped>
.table {
  height: auto;
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  background: @font-white-color;
  box-shadow: 0 0 10px #d7d7d7;
  border-radius: 10px;
  .cont {
    padding: 0 20px;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .thead {
    background: #e5ebff;
    height: 70px;
    width: auto;
    display: inline-flex;
    .item {
      color: @font-color;
      font-size: @font-size;
      line-height: 70px;
      font-weight: bolder;
      font-family: "Regular";
      text-align: center;
      white-space: nowrap;
      padding-left: 20px;
      display: block;
    }
    .item:last-of-type {
      padding-right: 20px;
    }
  }
  .tbody {
    max-height: 800px;
    overflow-y: auto;
    margin-bottom: 30px;
    width: auto;
    display: inline-block;
    .tr {
      min-height: 72px;
      border-bottom: 1px solid #d2d2d2;
      display: flex;
      align-items: center;
      .item {
        color: @font-secondary-color;
        font-size: @fontmini-size;
        line-height: 36px;
        overflow: hidden;
        text-align: center;
        word-break: break-all;
        padding: 10px 0;
        padding-left: 20px;
        display: block;
      }
      .item:last-of-type {
        padding-right: 20px;
      }
    }
  }
}

.title {
  padding: 0 20px;
  display: flex;
  padding-top: 20px;
  margin-bottom: 20px;
  .itemcont{
    flex:1;
    min-width:0;
    display:flex;
  }
  .item {
    position: relative;
    padding-bottom: 20px;
    font-size: @subtitle-size;
    font-weight: bolder;
    font-family: "Regular";
    color: @font-color;
    line-height: @subtitle-size;
    margin-right: 30px;
    span {
      position: absolute;
      left: 5%;
      right: 5%;
      bottom: 0;
      height: 4px;
      border-radius: 2px;
      background: @theme-color;
      display: none;
    }
  }
  .on {
    span {
      display: block;
    }
  }
}
.title{
  button {
    background: none;
    font-size: @font-size;
    font-weight: bolder;
    font-family: "Regular";
    color: @font-color;
    line-height: @font-size;
    float: right;
  }
}
</style>
