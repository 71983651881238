<template>
  <div class="titletab">
    <div
      class="item"
      v-for="(item, index) in field.value"
      :class="item == current_path ? 'on' : ''"
      :key="index"
      @click="toPath(item)"
    >
      {{ index }}<span></span>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUpdated, toRefs, defineProps, ref } from "vue";
import { useRoute,useRouter } from "vue-router";
import mitts from "@/bus";
const route = useRoute();
const router = useRouter();

const props = defineProps({
  field: {
    type: Object,
    required: true,
  }
});
const current_path = ref('');

function toPath(url){
    mitts.emit("showLoading");
    router.push({
      path: url
    });
}

onMounted(() => {
  current_path.value = route.path;
});
onUpdated(()=>{
  current_path.value = route.path;
});
const { field } = toRefs(props);
</script>

<style lang="less" scoped>
.titletab {
  display: flex;
  padding: 24px 24px 0 24px;
  margin-bottom: 60px;
  .item {
    margin-right: 50px;
    font-size: @font-size;
    color: @font-white-color;
    position: relative;
    padding-bottom: 14px;
    span {
      position: absolute;
      background: @font-white-color;
      display: none;
      bottom: 0;
      left: 10%;
      right: 10%;
      height: 3px;
      border-radius: 1.5px;
    }
  }
  .on {
    font-weight: bolder;
    span {
      display: block;
    }
  }
}
</style>
