<template>
  <div class="echartbox">
    <div class="title">
      <div class="item" v-for="(item, index) in title" :class="api_url == item?'on' : ''" :key="index" @click="changeTab(item)">
        {{ index }}<span></span>
      </div>
    </div>
    <div :id="setId" class="echart"></div>
  </div>
</template>

<script setup>
import { onMounted, toRefs, defineProps, ref } from "vue";
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from "echarts/core";
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart, LineChart, PieChart } from "echarts/charts";
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
  ToolboxComponent,
} from "echarts/components";
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from "echarts/features";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";
import API from "@/axios";

const props = defineProps({
  field: {
    type: Object,
    required: false,
  },
  // title: {
  //   type: Array,
  //   required: true,
  // },
  echartData: {
    type: Object,
    required: false,
  },
  setId: {
    type: String,
    required: true,
  },
});
const { field, echartData, setId } = toRefs(props);
const title = ref([]);
const api_url = ref('');

// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  LineChart,
  PieChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  LegendComponent,
  ToolboxComponent,
]);

function getData() {
  let myChart = echarts.init(document.getElementById(setId.value));
  API({
    url: api_url.value,
    method: "post",
  }).then((res) => {
    myChart.setOption(res.data.map);
    // data.value = res.data.data;
  });
}

function changeTab(item){
  api_url.value = item;
  getData();
}

onMounted(() => {
  if(field.value){
    title.value = field.value.tab_title;
    api_url.value = field.value.url;
    getData();
  }else{
    let myChart = echarts.init(document.getElementById(setId.value));
    myChart.setOption(echartData.value);
  }
});
// 接下来的使用就跟之前一样，初始化图表，设置配置项
</script>
<style lang="less" scoped>
.echartbox {
  width: 100%;
  background: @font-white-color;
  box-shadow: 0 0 10px #d7d7d7;
  border-radius: 10px;
  margin-bottom: 30px;
}
.echart {
  height: 500px;
}

.title {
  padding: 0 30px;
  display: flex;
  padding-top: 20px;
  padding-bottom: 10px;
  .item {
    position: relative;
    padding-bottom: 20px;
    font-size: @subtitle-size;
    font-weight: bolder;
    font-family: "Regular";
    color: @font-color;
    line-height: @subtitle-size;
    margin-right: 30px;
    span {
      position: absolute;
      left: 5%;
      right: 5%;
      bottom: 0;
      height: 4px;
      border-radius: 2px;
      background: @theme-color;
      display: none;
    }
  }
  .on {
    span {
      display: block;
    }
  }
}
</style>
