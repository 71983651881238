<template>
  <div class="shoplist">
    <div class="subtitle" v-if="title || Object.keys(search).length>0" :style="titleColor?'color:'+titleColor:''">
      {{ title?title:'' }}
      <button type="button" v-if="Object.keys(search).length>0" @click="showSearch">搜索</button>
    </div>
    <div class="shopslist">
<!--      <van-list-->
<!--        v-model:loading="loading"-->
<!--        :finished="finished"-->
<!--        finished-text="没有更多了"-->
<!--        @load="onLoad"-->
<!--        offset="0"-->
<!--      >-->


        <div class="item" v-for="(item, index) in table_data" :key="index" :data-id="item.id?item.id:0">
          <div class="listtext" v-for="(tfield, k) in fields"  :key="k">
            <div class="itemname" v-html="tfield.text"></div>
            <div class="itemval" v-html="item[k]"></div>
          </div>
        </div>
<!--      </van-list>-->
    </div>
    <searchForm :searchfun="searchfun" :search="search" :searchData="search_data" v-if="showsearch" />
  </div>
</template>

<script setup>
import { toRefs, defineProps, onMounted, ref } from "vue";
import searchForm from "@/components/selectbox";
import VIEW from "@/axios/view";
import API from "@/axios";
const props = defineProps({
  field: {
    type: Object,
    required: false,
  },
  title: {
    type: String,
    required: false,
  },
  titleColor: {
    type: String,
    required: false,
  },
  shoplistdata: {
    type: String,
    required: false,
  },
});

const loading = ref(true);
const finished = ref(false);
const fields = ref([]);
const search = ref({});
const table_data = ref([]);
const data_url = ref('');
const showsearch = ref(false);
let api_url = '';
let current_page = 1;
// let has_page = false;
let search_data = ref({});

const { field, title, titleColor } = toRefs(props);

onMounted(() => {
  if(field.value){
    api_url = field.value.url;
    getFields();
  }
});

// function onLoad() {
//   if(has_page){
//     console.log('2222'+current_page)
//     getData();
//   }else{
//      finished.value = true;
//      loading.value = false;
//   }
// }

function getApiUrl(){
  if(data_url.value.indexOf('?') === 0){
    if(api_url.indexOf('?') > -1){
      return api_url.substr(0, api_url.indexOf('?')) + data_url.value;
    }else{
      return api_url + data_url.value;
    }
  }
  return data_url.value;
}


function getFields() {
  VIEW({
    url: field.value.url,
    method: "post",
  }).then((res) => {
    let data = res.data.data;
    let tfields = data[1]['form']['field'];
    let onlys = field.value.only;
    if(onlys){
      onlys = Object.values(onlys);
      for(let i in tfields){
        if(onlys.indexOf(i) == -1){
          delete tfields[i];
        }
      }
    }
    fields.value = tfields;
    let tsearch = data[1]['form']['search'];
    for(let i in tsearch){
      if(tsearch[i].default){
        search_data.value[i] = tsearch[i].default;
      }else{
        search_data.value[i] = '';
      }
      search.value[i] = tsearch[i];
    }
    data_url.value = data[1]['form']['data_url'];
    // has_page = data[1]['form']['page'] === false ?false:true;
    getData();
  });
}

function getData() {
  let url = getApiUrl();
  let limit = 15;
  let data = {};
  data.page = current_page;
  data.limit = limit;
  data.search = search_data.value;
  loading.value = true;
  API({
    url: url,
    method: "post",
    data:data,
  }).then((res) => {
    if(current_page == 1){
        table_data.value = res.data.data;
    } else {
        table_data.value = table_data.value.concat(res.data.data);
    }
    if(res.data.data.length < limit){
        finished.value = true;
    } else {
        finished.value = false;
    }
    loading.value = false;
    current_page++;
  });
}

function showSearch() {
  showsearch.value = true;
}
function searchfun(data, is_ok) {
  showsearch.value = false;
  if(is_ok){
    current_page = 1;
    search_data.value = data;
    getData();
  }
}
</script>

<style lang="less" scoped>
.shoplist {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
}
.subtitle {
  font-size: @subtitle-size;
  font-weight: bolder;
  font-family: "Regular";
  color: @font-color;
  line-height: @subtitle-size;
  padding: 20px 30px 0 30px;
  margin-bottom: 20px;
}
.shopslist {
  padding: 10px 30px 0 30px;
  flex-grow: 1;
  min-height: 10px;
  overflow-y: auto;
  .item {
    padding: 20px 30px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: @font-white-color;
    box-shadow: 0 0 10px #d7d7d7;
    border-radius: 10px;
    margin-bottom: 40px;
    .listtext {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0;
      .itemname {
        font-size: @font-size;
        font-weight: bolder;
        font-family: "Regular";
        color: @font-secondary-color;
        line-height: @font-size;
      }
      .itemval {
        font-size: @font-size;
        font-family: "Regular";
        color: @font-third-color;
        line-height: @title-size;
        text-align: right;
        max-width: 80%;
      }
    }
  }
}
.subtitle{
  button {
    background: none;
    font-size: @font-size;
    font-weight: normal;
    font-family: "Regular";
    color: @font-white-color;
    line-height: @font-size;
    float: right;
  }
}
</style>
